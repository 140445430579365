import { useContext } from 'react'
import OrderContext, { OrderContextInterface } from './context'

/**
 * ```js
 * const {
 *   // Order state:
 *   newOrders
 * 	 preparingOrders
 * 	 readyOrders
 * 	 finishedOrders
 * 	 isLoading
 * 	 error
 *   error,
 *   // Order methods:
 *   fetchOrders
 *   confirmOrder
 * } = useOrder()
 * ```
 *
 * Use the `useOrder` hook in your components to access the auth state and methods.
 */
const useOrder = (): OrderContextInterface => useContext(OrderContext)

export default useOrder
