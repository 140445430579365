import { OrderState } from './state'
import { Order } from '../../types/order'

type Action =
	| { type: 'SET_LISTEN_NEW_ORDERS' }
	| { type: 'GET_ORDERS_STARTED' }
	| { type: 'GET_ORDERS_FINISHED'; orders: Order[] }
	| { type: 'CONFIRM_ORDER_STARTED' }
	| { type: 'CONFIRM_ORDER_FINISHED'; orderId: number }
	| { type: 'COMPLETE_ORDER_STARTED' }
	| { type: 'COMPLETE_ORDER_FINISHED'; orderId: number }
	| { type: 'FIND_ADMIN_STARTED' }
	| { type: 'ERROR'; error?: Error }

/**
 * Handles how that state changes in the `useOrder` hook.
 */
export const reducer = (state: OrderState, action: Action): OrderState => {
	switch (action.type) {
		case 'SET_LISTEN_NEW_ORDERS':
			return {
				...state,
				listenNewOrders: true,
			}
		case 'GET_ORDERS_STARTED':
			return {
				...state,
				isLoading: true,
			}
		case 'GET_ORDERS_FINISHED':
			return {
				...state,
				orders: action.orders,
				isLoading: false,
				hasNewOrder: action.orders.filter((order) => order.status === 'ordered').length > 0,
			}
		case 'CONFIRM_ORDER_STARTED':
			return {
				...state,
			}
		case 'CONFIRM_ORDER_FINISHED':
			return {
				...state,
				orders: state.orders.reduce((acc: Order[], cur) => {
					if (cur.id === action.orderId) {
						cur.status = 'confirmed'
					}
					acc.push(cur)
					return acc
				}, []),
			}
		case 'COMPLETE_ORDER_STARTED':
			return {
				...state,
			}
		case 'COMPLETE_ORDER_FINISHED':
			return {
				...state,
				orders: state.orders.filter((order) => order.id !== action.orderId),
			}
		case 'ERROR':
			return {
				...state,
				isLoading: false,
				error: action.error,
			}
		default:
			return { ...state }
	}
}
