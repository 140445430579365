import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Box, Center, keyframes, usePrefersReducedMotion } from '@chakra-ui/react'

type Props = {
	orderCount: number
}

export const NewOrderOverlay = (props: Props) => {
	const { orderCount } = props

	const blink = keyframes`
		0% { background-color: teal; }
		50% { background-color: #4FD1C5; }
	  100% { background-color: teal; }
	`

	const prefersReducedMotion = usePrefersReducedMotion()
	const animation = prefersReducedMotion ? undefined : `${blink} infinite 2s linear`

	const router = useRouter()

	const [showOverlay, setShowOverlay] = useState(false)

	useEffect(() => {
		setShowOverlay(orderCount > 0)
	}, [orderCount])

	useEffect(() => {
		const audio = document.getElementById('notify')
		/* eslint-disable */
		// @ts-ignore
		audio && audio.play()
	}, [showOverlay])

	const handleClick = (): void => {
		setShowOverlay(false)
		router.push('/orders')
	}

	return showOverlay ? (
		<>
			<Box
				animation={animation}
				color="white"
				position="fixed"
				top={0}
				right={0}
				bottom={0}
				left={0}
				onClick={handleClick}
				cursor="pointer"
			>
				<Box
					position="absolute"
					top="50%"
					left="50%"
					transform=" translateY(-50%) translateX(-50%)"
				>
					<Center fontSize={64}>新規注文</Center>
					<Center fontSize={128}>{orderCount}</Center>
				</Box>
			</Box>
			{/* eslint-disable */}
			<audio id="notify" loop>
				<source src="/notify.mp3" type="audio/mpeg" />
			</audio>
		</>
	) : (
		<></>
	)
}
