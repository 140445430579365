import { createContext } from 'react'
import { OrderState, initialOrderState } from './state'

export interface OrderContextInterface extends OrderState {
	fetchOrders: () => Promise<void>
	confirmOrder: (orderId: number) => Promise<void>
	completeOrder: (orderId: number) => Promise<void>
}

const stub = (): never => {
	throw new Error('You forgot to wrap your component in <OrderProvider>.')
}

const initialContext = {
	...initialOrderState,
	fetchOrders: stub,
	confirmOrder: stub,
	completeOrder: stub,
}

const OrderContext = createContext<OrderContextInterface>(initialContext)

export default OrderContext
