import React, { useReducer, useEffect } from 'react'
import {
	Button,
	Center,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	Stack,
	Heading,
	Box,
} from '@chakra-ui/react'
import * as Sentry from '@sentry/browser'
import { axios } from '../../lib/axios-client'
import { firestore } from '../../lib/firebase-client'
import OrderContext from './context'
import { reducer } from './reducer'
import { initialOrderState } from './state'
import { NewOrderOverlay } from '../../components/NewOrderOverlay'
import { OrderStatus } from '../../constants/order'

export interface Props {
	children?: React.ReactNode
}

export const OrderProvider = (props: Props) => {
	const { children } = props

	const [state, dispatch] = useReducer(reducer, initialOrderState)

	const fetchOrders = async (): Promise<void> => {
		dispatch({ type: 'GET_ORDERS_STARTED' })
		try {
			const res = await axios.get(`${process.env.REST_ENDPOINT}/admin/api/v1/orders`, {
				params: {
					status: ['ordered', 'confirmed'],
				},
			})
			console.log(res)
			dispatch({ type: 'GET_ORDERS_FINISHED', orders: res.data.orders })
		} catch (error: any) {
			Sentry.captureException(error)
			dispatch({ type: 'ERROR', error })
		}
	}

	const confirmOrder = async (orderId: number): Promise<void> => {
		dispatch({ type: 'CONFIRM_ORDER_STARTED' })
		try {
			await axios.patch(`${process.env.REST_ENDPOINT}/admin/api/v1/orders/${orderId}`, {
				status: OrderStatus.CONFIRMED,
			})
			dispatch({ type: 'CONFIRM_ORDER_FINISHED', orderId })
		} catch (error: any) {
			Sentry.captureException(error)
			dispatch({ type: 'ERROR', error })
		}
	}

	const completeOrder = async (orderId: number): Promise<void> => {
		dispatch({ type: 'COMPLETE_ORDER_STARTED' })
		try {
			await axios.patch(`${process.env.REST_ENDPOINT}/admin/api/v1/orders/${orderId}`, {
				status: OrderStatus.PICKED_UP,
			})
			dispatch({ type: 'COMPLETE_ORDER_FINISHED', orderId })
		} catch (error: any) {
			Sentry.captureException(error)
			dispatch({ type: 'ERROR', error })
		}
	}

	const startListeningNewOrders = async (): Promise<void> => {
		firestore.collection('orders').onSnapshot(() => {
			// TODO: ログインしている店舗の注文の場合のみ注文取得APIを叩く
			fetchOrders()
		})
		dispatch({ type: 'SET_LISTEN_NEW_ORDERS' })
	}

	useEffect(() => {
		setInterval(() => {
			fetchOrders()
		}, 15 * 60 * 1000)
	}, [])

	return (
		<OrderContext.Provider
			value={{
				...state,
				fetchOrders,
				confirmOrder,
				completeOrder,
			}}
		>
			{children}
			<Modal isOpen={!state.listenNewOrders} onClose={() => console.log('')} isCentered>
				<ModalOverlay />
				<ModalContent>
					<ModalBody p={8}>
						<Stack spacing={8}>
							<Center>
								<Heading size="lg">ナガタクへようこそ！</Heading>
							</Center>
							<Center>
								<Box>
									<Button colorScheme="teal" onClick={() => startListeningNewOrders()}>
										新規注文をチェック
									</Button>
								</Box>
							</Center>
						</Stack>
					</ModalBody>
				</ModalContent>
			</Modal>
			<NewOrderOverlay
				orderCount={state.orders.filter((order) => order.status === 'ordered').length}
			/>
		</OrderContext.Provider>
	)
}

export default OrderProvider
